
import URI from 'urijs';
const api = process.env.REACT_APP_API;

export const fetchMatchInfo = (id) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };
    let url = URI(`${api}/sport/matches/${id}`);
  
    return fetch(url.toString(), options)
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => response.json());
  } catch (error) {
    throw new Error(error)
  }

};